* {
   padding: 0;
   margin: 0;
   box-sizing: border-box;
}
.main {
   min-height: 100vh;

   background-color: black;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}

.clockFace {
   height: 300px;
   width: 300px;
   border: 10px dotted rgba(255, 255, 255, 0.411);
   border-radius: 50%;
}
.clock {
   position: relative;
   height: 100%;
   transform: translateY(-10px);
}

.hand {
   position: absolute;
   width: 48%;
   height: 6px;
   top: 50%;
   transform-origin: 100%;
   margin: 5px;
   transform: rotate(90deg);
   transition: all 0.05s;
   transition-timing-function: cubic-bezier(0.1, 2.7, 0.58, 1);
   box-shadow: 0px 0px 13px -3px rgba(255, 255, 255, 0.75);
   -webkit-box-shadow: 0px 0px 13px -3px rgba(255, 255, 255, 0.75);
   -moz-box-shadow: 0px 0px 13px -3px rgba(255, 255, 255, 0.75);
}

.secondHand {
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: white;
}
.minuteHand {
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: rgba(4, 0, 255, 0.603);
}
.hourHand {
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: rgb(255, 0, 0);
}

.labels {
   font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
   margin: 20px;
   color: white;
   display: flex;
}

.labels > h1 {
   text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.87);
}

.amPM {
   margin: 0 5px;
}
